<template>
	<form @submit.prevent="createOrUpdateOleUserDetails">
		<recess-modal variant="variant2" :show-modal="isModalVisible" @close="close">
			<template slot="modal-title">
				<h3 class="mt-2">
					{{ PROVIDER.EducationalFulfillmentPage.Popup.OleAccess.Title }}
				</h3>
			</template>

			<template slot="modal-body">
				<div class="row">
					<p class="col-12 col-md-11 mb-4">
						{{ PROVIDER.EducationalFulfillmentPage.Popup.OleAccess.Description }}
					</p>

					<p v-if="isOldOrder || hasNoProviderOleTypes" class="col-12 col-md-11">
						{{ PROVIDER.EducationalFulfillmentPage.Popup.OleAccess.HasNoLMSConfigured }}
					</p>

					<div v-else class="col-12 col-md-6">
						<!-- show list of ole types from provider api -->
						<recess-multi-select
							:selected-options="oleUserDetails.oleType"
							:options="oleTypeOptions"
							:single-select="true"
							:error-message="oleTypeError"
							:label-text="PROVIDER.EducationalFulfillmentPage.Popup.OleAccess.OleType.Label"
							:select-options-text="PROVIDER.EducationalFulfillmentPage.Popup.OleAccess.OleType.Placeholder"
							class="qa-ole-type-select mb-3"
							@input="(newValue) => onOleTypeChange(newValue)"
						/>

						<recess-input
							v-model="oleUserDetails.oleUserId"
							:label-text="PROVIDER.EducationalFulfillmentPage.Popup.OleAccess.OleUserId.Label"
							:placeholder-text="PROVIDER.EducationalFulfillmentPage.Popup.OleAccess.OleUserId.Placeholder"
							class="qa-ole-user-id-current"
						/>
					</div>
				</div>
			</template>

			<template slot="modal-footer">
				<div class="row">
					<div class="col-12 d-flex justify-content-end">
						<recess-button variant="secondary" type="submit" :title="PROVIDER.EducationalFulfillmentPage.Popup.OleAccess.SubmitButton" class="qa-ole-user-submit-button" />
					</div>
				</div>
			</template>
		</recess-modal>
	</form>
</template>

<script>
import { mapState } from 'vuex'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import { getItems, patchItem, postItem } from '@/../../shared/api/SharedClient'

import { API_CALL_URL_PATHS, WAIT_TIME } from '@/../../shared/constants/constantsGeneral.json'
import { PROVIDER } from '@/constants/EdumsProviderConstants.json'
import { isRequiredErrorMessage, oleUserSuccessAction, defaultErrorMessage } from '@/../../shared/constants/validationMessageHelper.js'

export default {
	name: 'OleAccessPopupComponent',
	mixins: [validationMixin],
	props: {
		isModalVisible: {
			type: Boolean,
			default: false
		},
		oleTypesProp: {
			type: Array,
			default: () => {
				return []
			}
		},
		educationalReferenceProp: {
			type: Object,
			default: () => {
				return {}
			}
		}
	},
	validations: {
		oleUserDetails: {
			oleType: { required }
		}
	},
	data() {
		return {
			PROVIDER,
			isTouched: false,
			timeout: null,
			oleTypeOptions: [],
			providerOleTypes: [],
			originalOleUserList: [],
			formattedOleUserList: [],
			oleUserDetails: {
				educationalCareerId: null,
				oleType: null,
				oleUserId: null
			},
			oleUserDetailsId: null
		}
	},
	computed: {
		...mapState('orderModule', ['order']),
		hasOleUser() {
			return this.oleUserDetails && this.oleUserDetails.oleUserId && this.oleUserDetails.educationalCareerId
		},
		setFilterProviderId() {
			return `providerId eq ${this.educationalReferenceProp.providerReferenceId}`
		},
		oleTypeError() {
			return isRequiredErrorMessage('oleType', 'Leer Management Systeem', this.$v.oleUserDetails.oleType)
		},

		isOldOrder() {
			return this.isModalVisible && (!this.educationalReferenceProp.educationalCareerId || !this.educationalReferenceProp.educationalFulfillmentId)
		},
		hasNoProviderOleTypes() {
			return this.oleTypeOptions.length === 0
		}
	},
	watch: {
		isModalVisible: function watchPropsChange(newValue) {
			// reset data when modal is closed by watching the value
			// change of isModalvisible from its parent 'OrderList'
			if (newValue === false) {
				this.resetData()
			}

			if (newValue === true) {
				this.getOleUserList()
			}
		}
	},

	beforeDestroy() {
		clearTimeout(this.timeout)
	},
	methods: {
		close() {
			this.$emit('close')
		},
		onTouch() {
			this.isTouched = true
		},
		resetData() {
			this.$v.$reset()
			this.isTouched = false
			this.oleTypeOptions = []
			this.providerOleTypes = []
			this.originalOleUserList = []
			this.formattedOleUserList = []
			this.oleUserDetails = {
				educationalCareerId: null,
				oleType: null,
				oleUserId: null
			}
			this.oleUserDetailsId = null
		},
		onOleTypeChange(newValue) {
			this.oleUserDetails.oleType = newValue
			// when user selects a new oleType in recess select dropdown
			// set oleUserId & educationalCareerId values from the formattedOleUserList
			// based on the selected ole type

			this.formattedOleUserList.forEach((oleUser) => {
				if (oleUser.oleType === newValue) {
					this.oleUserDetails.educationalCareerId = oleUser.educationalCareerId
					this.oleUserDetails.oleUserId = oleUser.oleUserId
					this.oleUserDetailsId = oleUser.id
				}
			})
		},
		setOleTypeOptions() {
			if (this.oleTypesProp && this.oleTypesProp.length > 0) {
				// transform provider oleTypes got from server to options to be used in recess-select

				this.oleUserDetails.oleType = this.oleTypesProp.forEach((oleType) => {
					if (!oleType.hasSso) return

					const option = {
						value: oleType.oleType,
						displayText: oleType.oleTypeDisplayValue
					}

					this.oleTypeOptions.push(option)
				})
			}

			if (this.oleTypeOptions.length === 1) {
				this.onOleTypeChange(this.oleTypeOptions[0].value)
			}
		},
		async getOleUserList() {
			if (!this.educationalReferenceProp.educationalCareerId) return
			try {
				const response = await getItems(
					process.env.VUE_APP_FULFILLMENT_API_URL,
					API_CALL_URL_PATHS.oleUsers,
					1,
					99999,
					null,
					`educationalCareerId eq ${this.educationalReferenceProp.educationalCareerId}`,
					false
				)
				if (!response) return
				this.originalOleUserList = response.items
				this.formatOleUserListWithProviderOleTypes()

				this.setOleTypeOptions()
			} catch (error) {
				console.error('Something went wrong while retrieving ole user list', error)
			}
		},
		formatOleUserListWithProviderOleTypes() {
			if (!this.originalOleUserList || this.originalOleUserList.length === 0 || !this.oleTypesProp || this.oleTypesProp.length === 0) return
			// loop through all providerOleTypes from this provider
			this.oleTypesProp.forEach((providerOleType) => {
				// check if this provider ole type exists in the originalOleUserList
				// if yes, we push to the formattedOleUserList
				const providerOleUser = this.originalOleUserList.find((oleUser) => oleUser.oleType === providerOleType.oleType)
				if (providerOleUser !== undefined) {
					this.formattedOleUserList.push(providerOleUser)
				} else {
					// if providerOleType doesn't exist
					// we create a newOleUser object that contain this providerOleType,
					// and set educationalCareerId & oleUserId to null which can be created by the provider in this form
					providerOleType.educationalCareerId = null
					providerOleType.oleUserId = null
					this.formattedOleUserList.push(providerOleType)
				}
			})
		},
		createOrUpdateOleUserDetails() {
			this.$v.$touch()
			if (!this.$v.$invalid) {
				// If ole user doesn't exist, we POST oleUser wtih educationalCareerId, oleType, oleUserId via fulfillment api
				if (!this.hasOleUser) {
					// only set educationalCareerId to oleUserDetails just before POST, in order to allow disabling oleUserId input field
					// when user is still editing the form
					this.oleUserDetails.educationalCareerId = this.educationalReferenceProp.educationalCareerId
					this.postOleUser(this.oleUserDetails)
				} else {
					// If oleUser already exists, we PATCH oleType via fulfillment api
					this.patchOleType(this.educationalReferenceProp.educationalFulfillmentId, this.oleUserDetails.oleType)
					this.patchOleUser(this.oleUserDetailsId, this.oleUserDetails.oleUserId)
				}
				this.timeout = setTimeout(() => this.$emit('close'), WAIT_TIME.closeModal)
			}
		},
		postOleUser(oleUserDetails) {
			if (!oleUserDetails) return
			postItem(process.env.VUE_APP_FULFILLMENT_API_URL, API_CALL_URL_PATHS.oleUsers, oleUserDetails, false)
				.then((response) => {
					if (!response) return
					// after POST is successful, we patch oleType to educationalFulfillment api with this order's educationalFulfillmentId
					this.patchOleType(this.educationalReferenceProp.educationalFulfillmentId, this.oleUserDetails.oleType)

					const toastNotification = {
						type: 'success',
						message: PROVIDER.EducationalFulfillmentPage.Popup.OleAccess.SuccessMessages.PostOleUser
					}

					this.$store.dispatch('toastNotificationModule/add', toastNotification, {
						root: true
					})
				})
				.catch((error) => {
					this.getNotificationError()
					console.error('Something went wrong while posting ole user details', error)
				})
		},
		patchOleUser(oleUserIdToPatch, newOleUserId) {
			if (!oleUserIdToPatch || !newOleUserId) return
			const oleUserToBePatched = [
				{
					value: newOleUserId,
					path: 'oleuserid',
					op: 'replace'
				}
			]
			patchItem(process.env.VUE_APP_FULFILLMENT_API_URL, API_CALL_URL_PATHS.oleUsers, oleUserIdToPatch, oleUserToBePatched, false)
				.then((response) => {
					if (!response) return

					const toastNotification = {
						type: 'success',
						message: oleUserSuccessAction('bewerkt')
					}

					this.$store.dispatch('toastNotificationModule/add', toastNotification, {
						root: true
					})
				})
				.catch((error) => {
					this.getNotificationError()
					console.error('Something went wrong while patching provider oleUserId', error)
				})
		},
		patchOleType(educationalFulfillmentId, newOleType) {
			if (!educationalFulfillmentId || !newOleType) return

			const oleTypeToBePatched = [
				{
					value: newOleType,
					path: 'oleType',
					op: 'replace'
				}
			]
			patchItem(process.env.VUE_APP_FULFILLMENT_API_URL, API_CALL_URL_PATHS.educationalFulfillments, educationalFulfillmentId, oleTypeToBePatched, false)
				.then((response) => {
					if (!response) return
					const toastNotification = {
						type: 'success',
						message: oleUserSuccessAction('bewerkt')
					}

					this.$store.dispatch('toastNotificationModule/add', toastNotification, {
						root: true
					})
				})
				.catch((error) => {
					this.getNotificationError()
					console.error('Something went wrong while patching provider ole type', error)
				})
		},
		getNotificationError() {
			const toastNotification = {
				type: 'error',
				message: defaultErrorMessage
			}

			this.$store.dispatch('toastNotificationModule/add', toastNotification, {
				root: true
			})
		}
	}
}
</script>
